// Typography
:root {
  --toastify-text-color-warning: #0f0f0f;
}

html {
  font-size: 14.5px;
}
body {
  color: #363636;
}
body,
input,
textarea,
select {
  font-weight: 400;
  font-family: 'Lato', Arial, sans-serif;
}

// Reset
*,
:after,
:before {
  box-sizing: border-box;
}

// App
html,
body,
#app {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

#app {
  display: flex;
}

// Print
@media print {
  @page {
    margin: 0;
  }
}

// Links
a,
a:visited,
a:active,
a:focus {
  text-decoration: none;
}

.gm-style-iw-d {
  margin-top: -35px;
}

// Modal
.prix-modal {
  position: absolute;
  top: 0;
  left: 60%;
  right: 0;
  bottom: 0;
  background-color: #f8f8f8;
  box-shadow: 2px 4px 12px rgba(100, 100, 100, 0.2);
  z-index: 11;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    & {
      left: 0;
    }
  }
}

.prix-modal-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  left: 83px;

  @media (max-width: 768px) {
    left: 0;
    bottom: 58px;
  }
}

.prix-floating-modal-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  left: 83px;

  @media (max-width: 768px) {
    left: 0;
    bottom: 58px;
  }
}

.prix-home-screen-modal-overlay {
  position: fixed;
  top: 202px;
  left: 83px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

  z-index: 10;

  @media (max-width: 768px) {
    left: 0;
    bottom: 58px;
    top: 120px;
  }
}

.prix-screen-modal-overlay-bigger-card {
  position: fixed;
  top: 130px;
  left: 83px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

  z-index: 10;

  @media (max-width: 768px) {
    left: 0;
    bottom: 58px;
    top: 160px;
  }
}

.prix-half-screen-modal-overlay {
  position: fixed;
  top: 130px;
  left: 83px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

  z-index: 10;

  @media (max-width: 768px) {
    left: 0;
    bottom: 58px;
    top: 120px;
  }
}

.prix-fullscreen-modal-overlay {
  position: fixed;
  top: 130px;
  left: 83px;
  right: 0;
  bottom: 0;
  z-index: 10;

  @media (max-width: 1024px) {
    left: 0;
    top: 60px;
  }
}

.prix-notification-modal {
  position: absolute;
  z-index: 9999;
}
